// 按需引入vant 组件库
import Vue from 'vue'

import {Divider,Notify,Col,Row, Tabbar, TabbarItem ,NavBar ,Toast,Dialog,Swipe, SwipeItem,Search,Tag} from 'vant';


//Tabbar
Vue.use(Tabbar);        
Vue.use(TabbarItem);  
//NavBar 导航栏
Vue.use(NavBar);    
// 提示文字
Vue.use(Toast);    

// 确定弹窗
Vue.use( Dialog); 

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Search)
Vue.use(Tag)
Vue.use(Col);
Vue.use(Row);
Vue.use(Notify);
Vue.use(Divider);

