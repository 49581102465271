
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

// 引入按需导入封装的vant
import '@/utils/vant-ui'  
// 引入默认样式
import '@/styles/common.css'


Vue.config.productionTip = false

new Vue({
  store,
  router,

  render: h => h(App)
}).$mount('#app')
